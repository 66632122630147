'use client';

import {
  AlertToast,
  AlertTriangle,
  Button,
  Input,
  Label,
  Typography,
} from '@v2/ui';
import { cn } from '@v2/utils';
import CheckYourEmail from './CheckYourEmail';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { useCompleteAccountStore } from '@store/complete-account-store';
import { useRouter } from 'next/navigation';
import { ChangeEvent } from 'react';
import { toast } from 'react-hot-toast';
import { PasswordStrengthVisual } from '../components/PasswordStrengthVisual';
import { useSignup } from '../use-auth';

export function CompleteAccount({ redirectUrl }: { redirectUrl?: string }) {
  const router = useRouter();
  const userType = useAuthUserType();
  const { addState, clearState } = useCompleteAccountStore();

  const [
    {
      isEmailVerified,
      loading,
      formik: {
        dirty,
        handleSubmit,
        values,
        handleBlur,
        handleChange: handleFormChange,
        errors,
        touched,
      },
    },
  ] = useSignup({
    onSuccessCallback: () => {
      if (redirectUrl) {
        return router.push(redirectUrl);
      }
    },
    onErrorCallack: () => {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    addState({ [e.target.name]: e.target.value });

    handleFormChange(e);
  };

  if (isEmailVerified) return <CheckYourEmail />;

  return (
    <>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className={cn(
            'mb-3 text-center',
            userType === 'agency' && 'text-white'
          )}
        >
          Complete your account
        </Typography>
        <Typography
          variant="p"
          size={'tmd'}
          className={cn(
            'text-center font-medium',
            userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
          )}
        >
          Please match your account name to the ID you use while traveling, such
          as a passport or driver’s license.
        </Typography>
      </div>
      <form
        action="post"
        onSubmit={handleSubmit}
        className="flex w-full flex-col gap-y-8"
      >
        <div className="flex w-full flex-col gap-y-5">
          <div className="flex w-full flex-row items-start gap-x-4">
            <div className="f grid w-full items-center gap-1.5">
              <Label
                htmlFor="firstName"
                className={cn(userType === 'agency' && 'text-gray-400')}
              >
                First Name
              </Label>
              <Input
                name="firstName"
                type="text"
                id="firstName"
                autoComplete="given-name"
                required
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={errors.firstName !== undefined && touched.firstName}
                errorText={errors.firstName}
              />
            </div>
            <div className="f grid w-full items-center gap-1.5">
              <Label
                htmlFor="lastName"
                className={cn(userType === 'agency' && 'text-gray-400')}
              >
                Last name
              </Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                autoComplete="family-name"
                required
                onBlur={handleBlur}
                isError={errors.lastName !== undefined && touched.lastName}
                errorText={errors.lastName}
              />
            </div>
          </div>
          <div className="f grid w-full items-center gap-1.5">
            <Label
              htmlFor="password"
              className={cn(userType === 'agency' && 'text-gray-400')}
            >
              Password
            </Label>
            <Input
              name="password"
              type="password"
              id="password"
              value={values.password}
              onChange={handleChange}
              required
              isError={errors.password !== undefined && touched.password}
            />
          </div>
          {/* PASSWORD PROGRESS VISUAL */}
          <PasswordStrengthVisual password={values.password} />

          <div className="f grid w-full items-center gap-1.5">
            <Label
              htmlFor="repeatPasword"
              className={cn(userType === 'agency' && 'text-gray-400')}
            >
              Confirm password
            </Label>
            <Input
              name="repeatPassword"
              type="password"
              id="repeatPassword"
              value={values.repeatPassword}
              onChange={handleChange}
              required
              isError={
                errors.repeatPassword !== undefined && touched.repeatPassword
              }
              errorText={errors.repeatPassword}
            />
          </div>
        </div>
        <Button fullWidth size="xl" isLoading={loading} type="submit">
          Continue
        </Button>
      </form>
    </>
  );
}
