'use client';

import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { v2Links } from '@utils/navigation/links';
import { DataTablePagination } from '@v2/components';
import { DataTableHeader } from '@v2/components/DataTableHeader';
import { AGENCY_DISCOUNT_MAP, type AgencyAffiliateCode } from '@v2/types';
import {
  Badge,
  Input,
  SearchMD,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@v2/ui';
import { format } from 'date-fns';
import Link from 'next/link';

function CellWithFormatPricing({ price }: { price: number }) {
  return <div className="text-center">{price}</div>;
}

const multiColumnFilterFn: FilterFn<AgencyAffiliateCode> = (
  row,
  columnId,
  filterValue
) => {
  const searchableRowContent = `${row.original.id} ${row.original.code}  ${row.original.type}  `;
  return searchableRowContent.toLowerCase().includes(filterValue.toLowerCase());
};
export const columns: ColumnDef<AgencyAffiliateCode>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          ID
        </DataTableHeader>
      );
    },
    filterFn: multiColumnFilterFn,

    cell: ({ row }) => <>{row.getValue('id')}</>,
  },
  {
    accessorKey: 'code',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Code
        </DataTableHeader>
      );
    },
    filterFn: multiColumnFilterFn,

    cell: ({ row }) => <>{row.getValue('code')}</>,
  },
  // {
  //   filterFn: multiColumnFilterFn,
  //   accessorKey: 'type',
  //   header: ({ column }) => {
  //     return (
  //       <DataTableHeader
  //         column={column}
  //         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
  //       >
  //         Type
  //       </DataTableHeader>
  //     );
  //   },

  //   cell: ({ row }) => {
  //     // @ts-ignore
  //     return (
  //       <div className="text-nowrap">
  //         {/* @ts-ignore */}
  //         {AGENCY_DISCOUNT_MAP[row.getValue('type') || 'affiliateCode']}
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: 'amount',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Amount
        </DataTableHeader>
      );
    },
    cell: ({ row }) => {
      return <CellWithFormatPricing price={row.getValue('amount')} />;
    },
  },
  {
    accessorKey: 'usedTimes',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Used times
        </DataTableHeader>
      );
    },
    cell: ({ row }) => {
      return <CellWithFormatPricing price={row.getValue('usedTimes')} />;
    },
  },

  {
    accessorKey: 'status',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Status
        </DataTableHeader>
      );
    },
    cell: ({ row }) => {
      const status = row.getValue('status');
      const variant = status === 'active' ? 'success' : 'error';
      return (
        <Badge
          size="md"
          variant={variant}
          className="text-sm font-medium capitalize"
        >
          {/* @ts-ignore */}
          {status}
        </Badge>
      );
    },
  },
  {
    accessorKey: 'startDate',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Start date
        </DataTableHeader>
      );
    },
    cell: ({ row }) => {
      const startDate = row.getValue('startDate') as Date;
      const value = startDate
        ? format(new Date(startDate), 'MMM dd, yyyy')
        : '-';

      return <div className="text-nowrap">{value}</div>;
    },
  },
  {
    accessorKey: 'endDate',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          End date
        </DataTableHeader>
      );
    },
    cell: ({ row }) => {
      const endDate = row.getValue('endDate') as Date;
      const value = endDate ? format(new Date(endDate), 'MMM dd, yyyy') : '-';

      return <div className="text-nowrap">{value}</div>;
    },
  },
];

type AffiliateRewardsTableProps = {
  data: AgencyAffiliateCode[];
  agencyId: number;
};

type RewardCategory = 'All' | 'Gift Card' | 'Promo Code' | 'affiliateCode';

export function AffiliateRewardsTable({
  data,
  agencyId,
}: AffiliateRewardsTableProps) {
  console.log({ data });

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [tab, setTab] = React.useState<RewardCategory>('All');
  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      columnFilters,
      pagination,
    },
  });

  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  return (
    <>
      <div className="flex flex-col items-stretch gap-y-4 md:flex-row md:items-center md:justify-between">
        <h2 className="text-2xl font-bold text-gray-800 md:text-4xl">
          Rewards
        </h2>
        <div className="flex items-center gap-x-3">
          <Input
            extent={isTablet ? 'md' : 'sm'}
            onChange={(event) => {
              table.getColumn('id')?.setFilterValue(event.target.value);
              table.getColumn('code')?.setFilterValue(event.target.value);
            }}
            placeholder="Search in table"
            startIcon={<SearchMD className="text-gray-500" size="20" />}
          />
        </div>
      </div>
      {/*
      <div className="w-full max-w-[89vw]">
        <Tabs
          value={tab}
          onValueChange={(type: RewardCategory) => {
            setTab(type);
            const value = type === 'All' ? '' : type;
            table.getColumn('type')?.setFilterValue(value);
          }}
        >
          <TabsList variant="outline">
            <TabsTrigger variant="outline" value="All">
              All
            </TabsTrigger>
            <TabsTrigger variant="outline" value="giftCard">
              Gift Cards
            </TabsTrigger>
            <TabsTrigger variant="outline" value="promoCode">
              Coupons
            </TabsTrigger>
            <TabsTrigger variant="outline" value="affiliateCode">
              Affiliates
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div> */}

      <div className="mt-2 rounded-xl border border-gray-200 md:mt-0">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      <Link
                        prefetch={false}
                        href={`${v2Links.partnerPromoCodes}/${row.getValue('id')}?agencyId=${agencyId}`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Link>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <DataTablePagination table={table} />
    </>
  );
}
