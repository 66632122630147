'use client';
import { useAuthUserType } from '@hooks/use-auth-user-type';
import { AUTH_PROVIDERS } from '@utils/auth/providers';
import { v2Links } from '@utils/navigation/links';
import { Button, InputOTP, InputOTPGroup, InputOTPSlot } from '@v2/ui';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useConfirmIdentity } from '../use-auth';
import { INPUT_OTP_SLOTS } from '../util';
import { cn } from '@v2/utils';

export default function ClientContent() {
  const [value, setValue] = useState('');
  const searchParams = useSearchParams();
  const router = useRouter();
  const { update } = useSession();
  const userType = useAuthUserType();

  const email = searchParams!.get('email')!;

  const origEmail = decodeURIComponent(email);
  const emailParam = encodeURIComponent(email);

  const [
    { countDuration, loading },
    { handleResendCode, handleVerifyCode, startCountdown },
  ] = useConfirmIdentity({
    onSuccessCallack: async (res) => {
      await signIn(AUTH_PROVIDERS.est, {
        token: res.data.token.refreshToken,
        redirect: false,
      });
      setTimeout(() => {
        update();
        router.refresh();
      }, 500);
    },
  });

  useEffect(() => {
    startCountdown();
  }, []);

  return (
    <>
      {/*  OTP */}
      <div className="w-full">
        <InputOTP
          maxLength={INPUT_OTP_SLOTS}
          value={value}
          onChange={(value) => setValue(value)}
          render={({ slots }) => (
            <InputOTPGroup className="w-full">
              {slots.map((slot, index) => (
                <InputOTPSlot
                  key={index}
                  {...slot}
                  className={cn('w-full', userType === 'agency' && 'bg-white')}
                />
              ))}{' '}
            </InputOTPGroup>
          )}
        />
      </div>
      {/* KEEP ME SIGNED IN */}
      {/* <div className="flex flex-row gap-x-3 items-start ">
        <Checkbox />

        <div className="flex flex-col mb-1">
          <Typography size={'tmd'} className="text-gray-800">
            Keep me signed in
          </Typography>
          <Typography size={'tmd'} className="text-gray-500">
            Only select this on personal devices to keep your account secure.
          </Typography>
        </div>
      </div> */}

      {/* BUTTON */}
      <div className="flex flex-col gap-y-3">
        <Button
          fullWidth
          size="xl"
          className="w-full"
          disabled={value.length !== INPUT_OTP_SLOTS}
          onClick={() =>
            handleVerifyCode({ email: origEmail, code: value, userType })
          }
          isLoading={loading}
        >
          Continue
        </Button>

        <Button
          fullWidth
          variant="tertiary"
          size="xl"
          disabled={countDuration !== 0}
          onClick={() => handleResendCode(origEmail)}
        >
          Resend code
          {countDuration === 0 ? '' : ` in ${countDuration}s`}
        </Button>

        <Button
          fullWidth
          variant="tertiary"
          className="w-full text-primary-500"
          onClick={() => {
            router.push(
              `${v2Links.enterPassword}?email=${emailParam}&userType=${userType}`
            );
          }}
        >
          Enter password instead
        </Button>
      </div>
    </>
  );
}
