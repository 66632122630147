import { useCurrencyContext } from '@contexts/currency-context';
import { CAD_CURRENCY, CURRENCY_COOKIE, USD_CURRENCY } from '@utils/currency';
import getCookie from '@utils/get-cookie';
import { setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

export const CURRENCY_LIST = {
  [USD_CURRENCY]: {
    name: 'US dollar',
    slug: 'USD',
    flag: '/flags/usa.webp',
  },
  [CAD_CURRENCY]: {
    name: 'Canadian dollar',
    slug: 'CAD',
    flag: '/flags/canada.webp',
  },
};

export function useCurrencyDropdown(): [string, (currency: string) => void] {
  const { currency, setCurrency } = useCurrencyContext();
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    currency || USD_CURRENCY
  );

  const [isCadRateAvailable, setIsCadRateAvailable] = useState<boolean | null>(
    null
  );
  const TWO_MINUTES_IN_SECONDS = 120;

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(
          'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json',
          {
            next: { revalidate: TWO_MINUTES_IN_SECONDS },
          }
        );
        const data = await res.json();

        setIsCadRateAvailable(data?.usd?.cad);
      } catch (e) {
        setIsCadRateAvailable(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const currencyCookie = getCookie(CURRENCY_COOKIE) as string;
    if (currencyCookie) {
      setSelectedCurrency(currencyCookie);
    }
  }, []);

  useEffect(() => {
    setSelectedCurrency(currency);
  }, [currency]);

  useEffect(() => {
    const currencyCookie =
      (getCookie(CURRENCY_COOKIE) as string) || USD_CURRENCY;
    if (currencyCookie && selectedCurrency !== currencyCookie) {
      setCookie(CURRENCY_COOKIE, selectedCurrency);
      setCurrency(selectedCurrency);
    }

    if (
      selectedCurrency === CAD_CURRENCY &&
      isCadRateAvailable !== null &&
      !isCadRateAvailable
    ) {
      setSelectedCurrency(USD_CURRENCY);
    }
  }, [selectedCurrency, isCadRateAvailable]);

  return [selectedCurrency, setSelectedCurrency];
}
