import { LeagueEvent } from '@interfaces/APITravel';
import { getInternationalTime, getInternationalWeekDateTz } from '@utils/dates';
import { v2Links } from '@utils/navigation/links';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { getRelativeDateCategory } from '@v2/utils/date';
import { differenceInDays, format } from 'date-fns';
import Link from 'next/link';
import * as EventPreviewCard from '../../../../../components/EventPreviewCard/EventPreviewCard';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';

export function EventItemCard({
  id,
  occurs_at,
  occurs_at_local,
  performances,
  name,
  isItemFirst,
  isItemlast,
  venue: { location, name: city },
}: LeagueEvent & { isItemFirst: boolean; isItemlast: boolean }) {
  const dateFull = getInternationalWeekDateTz(occurs_at_local).split(', ');
  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  const day = dateFull[0];
  const date = dateFull[1];
  const time = getInternationalTime(occurs_at_local, true);
  const eventLink = name
    .concat(' ', day, ' ', date)
    .replaceAll(' ', '-')
    .replaceAll('.', '');
  const performers = performances?.filter(
    (performance) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      !performance.performer.name.toLowerCase().includes('playoff') &&
      !performance.performer.name.toLowerCase().includes('preseason') &&
      !performance.performer.name.toLowerCase().includes('frozen fury') &&
      !performance.performer.name.toLowerCase().includes('final')
  );

  if (performers.length === 1) return null;

  const { 0: firstPerformer, 1: secondPerformer = { performer: null } } =
    performers;
  const { performer: commandA } = firstPerformer;
  const { performer: commandB } = secondPerformer;

  const remainingDaysForTheEvent = differenceInDays(
    new Date(occurs_at.split('T')[0]),
    new Date(format(new Date(), 'yyyy-MM-dd'))
  );

  if (remainingDaysForTheEvent === 0) return null;

  const cardContent = <EventPreviewCard.Card
    key={id}
    className={cn(
      'border flex justify-between',
      isItemFirst && 'rounded-tl-lg rounded-tr-lg',
      isItemlast && 'rounded-bl-lg rounded-br-lg'
    )}
  >
    <EventPreviewCard.Row
      title={`${day} ${date}`}
      description={time}
      className='text-nowrap'
    />
    <EventPreviewCard.Row
      title={
        <Tooltip delayDuration={200}>
          <TooltipTrigger className="flex items-center">
            <span className="max-w-[20ch] sm:max-w-[30ch]  md:max-w-[26ch] xl:max-w-[40ch] truncate  ">
              {firstPerformer.primary ? commandB?.name : commandA.name} @{' '}
              {firstPerformer.primary ? commandA.name : commandB?.name}
            </span>


          </TooltipTrigger>
          <TooltipContent>
            <Typography size="txs" className="font-semibold text-gray-700">
              {firstPerformer.primary ? commandB?.name : commandA.name} @{' '}
              {firstPerformer.primary ? commandA.name : commandB?.name}
            </Typography>
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      }
      description={getRelativeDateCategory(occurs_at)}
      className='flex-1 basis-4/12'
    />
    <EventPreviewCard.Row
      title={location}
      description={city}
      descriptionClassName='truncate max-w-[20ch]'
      className="hidden  md:flex md:flex-col md:flex-1"
    />

    <EventPreviewCard.ActionButton

      action={() => { }}
      isFavorite
      link={`${v2Links.buy}?eventId=${id}&eventLink=${eventLink}`}
    />
  </EventPreviewCard.Card>



  if (isTablet)
    return cardContent


  return <Link
    prefetch={false}
    href={`${v2Links.buy}?eventId=${id}&eventLink=${eventLink}`}
    target="_blank"
  >
    {cardContent}
  </Link>
}
